body, html {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100vh;
	/* height: calc(var(--vh, 1vh) * 100); */
    /* overflow: hidden; */
}

body {
	background-color: #f5f5f5;
	font-size: 16px;
	color: #333;
	margin: 0;
	padding: 0;
	overflow-y: scroll;
}

#root {
	max-width: 100%;
	min-height: 100vh;
	/* min-height: calc(var(--vh, 1vh) * 100); */
}

.height100 {
	height: 100vh;
	/* height: calc(var(--vh, 1vh) * 100); */
}

@media (max-width: 576px) {
	.desktop-mode {
		display: none;
	}

	.mobile-mode {
		display: block;
	}
}

@media (min-width: 576px) {
	.desktop-mode {
		display: block;
	}

	.mobile-mode {
		display: none;
	}
}

.btn-function {
	transition: all 0.3s ease;
}

.btn-function:hover {
	transform: scale(1.1);
}

.input-highlight {
	box-shadow: 0 0 15px #F37C3B;
	border: 1px solid #F37C3B;
}

.bg-animation {
	animation: fadein .5s; 
}

@keyframes fadein {
	0% {
		opacity: 0;
	}
	/* 50%{
		opacity: 0.5;
	} */
	100% {
		opacity: 1;
	}
}

.bgSizeCover {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

* {
	-webkit-tap-highlight-color: transparent;
}